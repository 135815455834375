import React, { useState, useEffect, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import AstroCard from '../components/AstroCard';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { ConsultationContext } from '../context/Consultation';
import { getWebConfig, getwidgets, glanceSMS } from '../services/apiServices';
import b from '../staticAssets/gi.png';
import { navigate } from 'gatsby';
import { AnalyticsContext } from '../context/Analytics';

function Glance() {
    const [p, setp] = useState("");
    const [msg, setmsg] = useState("");
    const [widgets, setwidgets] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const { astrologerId } = useContext(ConsultationContext) || { astrologerId: "" };
    const { gtmPageViewEvent } = useContext(AnalyticsContext) || { gtmPageViewEvent: () => { } }
    const [config, setConfig] = useState()
    const [host, sethost] = useState("");

    useEffect(() => {
      sethost(window.location.host);
    }, []);
    useEffect(() => {
      const callConfig = async () => {
  
        try {
          let configDataResponse;
          const specialHosts = [
            'localhost:8000',
            process.env.GATSBY_JIO_BASE,
            process.env.GATSBY_MEESHO_BASE,
            process.env.GATSBY_NAMAH_BASE,
            process.env.GATSBY_LOKAL_BASE,
            process.env.GATSBY_BODHI_BASE,
            
          ];
    
          if (specialHosts.includes(host)) {
            configDataResponse = await getWebConfig(host);

            // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host );
          } else {
            // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host);
            configDataResponse = await getWebConfig(host);
          }
    
          if (configDataResponse) {
            setConfig(configDataResponse?.payLoad);
          }
        } catch (error) {
          console.error("Error fetching config data:", error);
        }
      };
    
      callConfig();
    }, [host]);
    
    useEffect(() => {
        gtmPageViewEvent(window.location.pathname,window.location.host);
    }, [])

    useEffect(() => {
        const fn = async () => {
            setisLoading(true);
            let res = await getwidgets("all", 1, 11);
            if (res.statusCode === 200) {
                setwidgets([...res.payLoad.consultWidgets]);
            }
            else {
            }
            setisLoading(false);
        }
        fn();

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let d = {
            phoneNumber: p,
            platform: "WEB_GLANCE",
        }
        let res = await glanceSMS(d);
        if (res.statusCode == 200) {
            setmsg(res.message);
            setp("");
            setTimeout(() => { setmsg("") }, 3000);
        }
    }

    const setStatus = (user, s) => {
        let index = -1;
        for (let i = 0; i < widgets.length; i++) {
            if (widgets[index]?.data?.user === user) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            let w = widgets;
            w[index].data.status = s;
            setwidgets({ ...w });
        }

    }

    return <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide">
        <Header />
        <div className="flex-1 overflow-y-auto flex flex-col">
            <main className="grow  hide-scroll-bar scrollbar-hide">
                <Carousel centerMode={false} showThumbs={false} infiniteLoop={true} autoPlay={true} showArrows={false} swipeable={true} showStatus={false}>

                    {/* <img loading="lazy" src={b} alt="astrology" title="astrology" /> */}
                    <div style={{ backgroundImage: `url(${b})` }} className="bg-cover h-52 md:h-96 flex flex-col items-center text-center">
                        <h1 className="text-2xl md:text-5xl font-semibold font-sans text-white mx-6 drop-shadow-lg inline mt-12 md:mt-36">Consult with Top</h1>
                        <h1 className="text-2xl md:text-5xl font-semibold font-sans text-white mx-6 drop-shadow-lg inline">Astrologers for ₹1 only</h1>
                        <h2 className="text-white text-sm md:text-lg my-1">Get answers for your love, marriage, & career issues</h2>
                        <div className="bg-white rounded-lg flex justify-around mt-1 items-center border-2 border-orange-500 border-dashed shadow-lg ">
                            <h3 className="mx-2 text-xs my-2 md:text-lg">Use Code:</h3>
                            <h3 className="mx-2 text-orange-500 font-bold text-sm md:text-lg">Glance</h3>
                            <button className="p-1 mx-2" onClick={() => { navigator.clipboard.writeText("GLANCE") }}>

                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Carousel>
                <div className="w-full flex justify-center my-2">
                    <h1 className="font-semibold text-xl font-sans">Consult with Top Astros</h1>
                </div>
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center ">

                        {widgets?.map((v, i) => {
                            if (widgets.length === i + 1) {
                                return (
                                    <div className="" key={i} >
                                        <AstroCard data={v} astrologerId={astrologerId} setStatus={setStatus} config={config}/>
                                    </div>
                                );
                            }
                            else {

                                return (
                                    <AstroCard key={i} data={v} astrologerId={astrologerId} setStatus={setStatus}  config={config}/>
                                );
                            }
                        })}
                        {isLoading && <div className="border border-gray-100  rounded-md p-4 max-w-sm w-11/12 m-4">
                            <div className="animate-pulse flex flex-row lg:flex-col space-x-4">
                                <div className="w-1/3 lg:w-full flex justify-center">
                                    <div className="rounded-full bg-gray-200 h-32 lg:h-44 w-32 lg:w-44"></div>
                                </div>
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="h-2 bg-gray-200 rounded"></div>
                                    <div className="space-y-3">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                        </div>
                                        <div className="h-2 bg-gray-200 rounded"></div>
                                    </div>
                                    <div className="space-y-3">
                                        <div className="grid grid-cols-3 gap-4">
                                            <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                        </div>
                                        <div className="h-2 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
                <div className="flex w-full justify-center my-6">
                    <button className="rounded-lg border-2 font-semibold border-orange-600 text-lg px-8 py-2 text-orange-600 hover:bg-orange-500 hover:text-white" onClick={() => { navigate('/consultAstro') }}>
                        Load more Astros

                    </button>

                </div>
            </main>
            <Footer />
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 flex flex-col justify-center bg-white absolute right-0 bottom-0 rounded-t-2xl border">

            <h2 className="m-2 font-semibold font-sans tracking-wide">Get upto ₹300 on 1st recharge</h2>
            <form onSubmit={handleSubmit}>
                <div className="flex border boder-gray-200 rounded-lg mx-2 mt-1 mb-4 overflow-hidden justify-between">

                    <input className="focus:outline-none p-1 text-sm flex-grow" placeholder="Enter Mobile Number" type="number" required value={p} onChange={(e) => { setp(e.target.value) }} />
                    <button className="bg-orange-500 text-white px-2 py-1 flex text-sm" onWheel={(e) => e.target.blur()} type="submit"><h2 className='inline-block'>Get App Link</h2></button>
                </div>
            </form>
            {msg.length > 0 && <h2 className="text-green-500 italic ml-2 mb-2">{msg}</h2>}

        </div>
    </div>;
}

export default Glance;
